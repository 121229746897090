import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

// Libraries
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss'
import 'swiper/modules/autoplay/autoplay.scss'
import 'swiper/modules/pagination/pagination.scss'

SwiperCore.use([Autoplay])

const Partner = styled(Link)`
  &:hover {
    opacity: 0.6;
  }

  &:hover h2 {
    text-decoration: none !important;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.m};
    font-weight: ${({ theme }) => theme.font.weight.l};
    text-align: center;
  }
`

const Line = styled.div`
  background: ${(props) => props.theme.color.gradient.main};
  height: 3px;
  border-radius: 1.5px;
  width: 20%;
  margin: 0 auto;
`

const PartnerImg = styled(Img)`
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (min-width: 992px) {
    height: 125px;
    width: 125px;
  }

  @media (max-width: 991.98px) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: 767.98px) {
    height: 90px;
    width: 90px;
  }

  @media (max-width: 575.98px) {
    height: 75px;
    width: 75px;
  }
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.l};
`

const StyledSwiperSlide = styled(SwiperSlide)`
  @media (max-width: 575.98px) {
    width: 100px !important;
    min-width: 100px !important;
  }
`

const PartnerSlider = ({ initialSlides = 5 }) => {
  const {
    partners: { nodes: partners },
  } = useStaticQuery(graphql`
    {
      partners: allWordpressWpPartner(sort: { fields: date }) {
        nodes {
          ...PreviewPartnersFrag
        }
      }
    }
  `)

  return (
    <div className="container">
      <Title className="mb-4 mb-lg-5">Onze partners</Title>
      <Swiper
        className="relative"
        autoplay={{
          delay: 5000,
        }}
        breakpoints={{
          768: {
            slidesPerView: initialSlides,
            spaceBetween: 30,
          },
          450: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          0: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        {partners.map((partner, index) => (
          <StyledSwiperSlide key={index}>
            <Partner to={partner.path}>
              <PartnerImg
                loading="eager"
                fadeIn={false}
                objectFit="contain"
                fluid={
                  partner.acf.preview.image.localFile.childImageSharp.fluid
                }
                alt={partner.title}
              />
              <h2 className="mt-3">{partner.title}</h2>
              <Line className="mt-2" />
            </Partner>
          </StyledSwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default PartnerSlider
